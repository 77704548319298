export enum IToastType {
  success = "success",
  error = "danger",
  primary = "primary",
  secondary = "secondary",
  danger = "danger",
  warning = "warning",
  info = "info",
  dark = "dark",
  light = "light",
  unknow = "undnow",
}
export enum IModalMessageType {
  success = "success",
  error = "danger",
  primary = "primary",
  secondary = "secondary",
  danger = "danger",
  warning = "warning",
  info = "info",
  dark = "dark",
  light = "light",
  unknow = "undnow",
}

export interface IModalMessage {
  show: boolean;
  type: IModalMessageType;
  text: string;
}

export interface IProduct {
  DHINI: string;
  CODVOL: string;
  STATUS: string;
  QTDPRODUZIR: number;
  DESCRPROD: string;
  DHINC: string;
  CODPROD: number;
  DUM: string;
  QTDPALLET: number;
  CODWCP: number;
  IDIPROC: number;
  IDIATV: number;
  IMAGEM: string;
  NUAPO?: string;
  STATUSAPO?: String;
  INICIADOAPO?: String;
  SALDO?: number;
}

export interface INote {
  ID?: number;
  CODWCP: number;
  IDIPROC: number;
  IDIATV: number;
  NUAPO: number;
  LISTAPEND: number;
  CODPROD: number;
  QTDEPROD: number;
  DHAPO: number;
  CODUSU: number;
  IMPRESSO?: string;
}
