import jwt_decode from "jwt-decode";

type DataToken = {
  data: {
    idUsuario: string;
    user: string;
  };
};

export class TokenDecode {
  private token: string;
  constructor(token: string) {
    this.token = token;
  }

  getIdUsuario = () => {
    try {
      const decoded = jwt_decode(this.token);
      const { idUsuario: idUsuaruiEncoded } = (decoded as DataToken).data;
      const buff = new Buffer(idUsuaruiEncoded, "base64");
      const idUsuario = Number(buff.toString("ascii"));
      return idUsuario;
    } catch (error) {}
  };
  getUserName = () => {
    try {
      const decoded = jwt_decode(this.token);
      const { user: userEncoded } = (decoded as DataToken).data;
      const buffUser = new Buffer(userEncoded, "base64");
      const userName = buffUser.toString("ascii");

      return userName;
    } catch (error) {}
  };
}
