import React, { useEffect, useRef } from "react";
import {
  BoldLabel,
  CardContainer,
  StyledCard,
  StyledCardProduct,
} from "./styles";
import { Card, Form, Button } from "react-bootstrap";
import { getLocalDate, getLocalTime } from "../../../utils/formatValue";
import { IProduct } from "../../../Shared/Interfaces";
import { MdArrowBack, MdPrint } from "react-icons/md";

interface IProps {
  selectedProduct: IProduct;
  noteType: string;
  setNoteType: (value: string) => void;
  setQuantity: (value: number) => void;
  handleSearchDUM: (event: any) => void;
  handleSubmit: () => void;
  isValidDUM: boolean;
  children: JSX.Element;
  noteTotal: string;
  setSelectedProduct: (value: any) => void;
  isMobile: boolean;
  handlePrint?: (reprint: string) => void;
}

export default function SelectedProduct({
  selectedProduct,
  noteType,
  setNoteType,
  setQuantity,
  handleSearchDUM,
  handleSubmit,
  isValidDUM,
  children,
  noteTotal,
  setSelectedProduct,
  isMobile,
  handlePrint,
}: IProps) {
  const dunRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (dunRef?.current) dunRef.current!.focus();
  }, [selectedProduct, noteType]);

  return (
    <div
      style={{
        flex: "7",
        padding: "5px",
        marginTop: "10px",
        maxWidth: `${window.innerWidth - 15}px`,
      }}
    >
      <div>
        <CardContainer>
          <StyledCardProduct text={"dark"} bg={"Light"} className="mb-2">
            <Card.Header
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "10px" }}
                >
                  <div>
                    <BoldLabel>Ordem Produção:</BoldLabel>{" "}
                    {selectedProduct.IDIPROC}
                  </div>
                  {handlePrint && (
                    <div>
                      <MdPrint
                        size={24}
                        style={{ cursor: "pointer" }}
                        onClick={() => handlePrint("R")}
                        color={"#dd5400"}
                      />
                    </div>
                  )}
                </div>
                <BoldLabel style={{ maxWidth: "290px" }}>
                  {selectedProduct.CODPROD} - {selectedProduct.DESCRPROD}
                </BoldLabel>
              </div>
              {isMobile && (
                <div>
                  <MdArrowBack
                    onClick={() => setSelectedProduct(null)}
                    size={32}
                    color="orange"
                  />
                </div>
              )}
            </Card.Header>
            <Card.Body style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: "5px" }}>
                <img
                  src={
                    selectedProduct?.IMAGEM
                      ? `data:imagem/png;base64,${selectedProduct.IMAGEM}`
                      : "/noimage.jpeg"
                  }
                  alt="imagem"
                  style={{ width: "100px", height: "auto" }}
                />
              </div>
              <div>
                <div style={{ marginBottom: "5px" }}>
                  <BoldLabel>Data:</BoldLabel>
                  {getLocalDate(selectedProduct.DHINC.toString())}{" "}
                  {getLocalTime(selectedProduct.DHINC.toString())}
                </div>
                <div style={{ marginBottom: "5px" }}>
                  <BoldLabel>Quantidade:</BoldLabel>
                  {selectedProduct.QTDPRODUZIR}
                </div>
                <div style={{ marginBottom: "5px" }}>
                  <BoldLabel>Quantidade Pallet:</BoldLabel>
                  {selectedProduct.QTDPALLET}
                </div>
                <div style={{ marginBottom: "5px" }}>
                  <BoldLabel>Código DUN:</BoldLabel>
                  {process.env?.REACT_APP_SHOW_DUN
                    ? selectedProduct.DUM
                    : `*********${selectedProduct.DUM.substring(9)}`}
                </div>
                <div style={{ marginBottom: "5px" }}>
                  <BoldLabel>Apontamento realizado:</BoldLabel>
                  {noteTotal}
                </div>
              </div>
            </Card.Body>
          </StyledCardProduct>

          <StyledCard text={"dark"} bg={"Light"} className="mb-2">
            <Card.Body>
              <Form.Group className="mb-3" controlId="formNumber">
                <Form.Check
                  type={"radio"}
                  label={`Pallet/Gaiola`}
                  value={"pallet"}
                  id={`idRadio`}
                  checked={noteType === "pallet"}
                  name={"idradio"}
                  onChange={(e) => setNoteType(e.target.value)}
                />
                <Form.Check
                  type={"radio"}
                  label={`Caixa/Fardo`}
                  value={"caixa"}
                  checked={noteType === "caixa"}
                  id={`idRadio`}
                  name={"idradio"}
                  onChange={(e) => setNoteType(e.target.value)}
                />
                {noteType !== "" &&
                  (noteType === "caixa" ? (
                    <>
                      <Form.Label style={{ marginTop: "15px" }}>
                        Quantidade
                      </Form.Label>
                      <Form.Control
                        key="key-quantity"
                        name="quantity"
                        type="number"
                        autoFocus
                        placeholder="Informe a quantidade"
                        onChange={(e) => setQuantity(Number(e.target.value))}
                      />
                    </>
                  ) : (
                    <div style={{ paddingRight: "10px" }}>
                      <Form.Label
                        style={{
                          marginTop: "15px",
                          marginLeft: "5px",
                        }}
                      >
                        Código DUN
                      </Form.Label>
                      <Form.Control
                        key="dun-code"
                        type="number"
                        name="dun-code"
                        placeholder="Pesquisa produtos - DUN"
                        ref={dunRef}
                        style={{
                          marginBottom: "10px",
                          marginLeft: "5px",
                          marginRight: "10px",
                        }}
                        autoFocus
                        onKeyDown={handleSearchDUM}
                        isInvalid={!isValidDUM}
                      />
                      <Form.Control.Feedback type="invalid">
                        O código DUN informado não é válido para o produto
                        selecionado!
                      </Form.Control.Feedback>
                    </div>
                  ))}
                {/* <Form.Switch style={{ marginTop: "10px" }} type="switch" label="Finalizar apontamentos" onChange={(e) => console.log(e)} /> */}
                {noteType === "caixa" && (
                  <div>
                    <Button
                      variant="warning"
                      style={{
                        marginTop: "15px",
                        width: "150px",
                        color: "white",
                      }}
                      onClick={handleSubmit}
                    >
                      OK
                    </Button>
                  </div>
                )}
              </Form.Group>
            </Card.Body>
          </StyledCard>
        </CardContainer>
      </div>
      {children}
    </div>
  );
}
