import React from "react";

import { Card } from "react-bootstrap";
import { INote } from "../../../Shared/Interfaces";
import { getLocalDate, getLocalTime } from "../../../utils/formatValue";
import { StyledCard } from "./styles";
import { MdPrint } from "react-icons/md";
import { api } from "../../../services/api";

interface IProps {
  notes: INote[];
  handlePrint?: (nuapo: string) => void;
}

export default function NotesHistory({ notes, handlePrint }: IProps) {
  return (
    <div>
      <StyledCard text={"dark"} bg={"Light"} className="mb-2">
        <Card.Body>
          <Card.Header>
            <span>Apontamentos Realizados</span>
          </Card.Header>
          <Card.Body>
            <table>
              <thead>
                <tr>
                  <th style={{ paddingRight: "5px" }}>Data</th>
                  <th style={{ paddingRight: "5px" }}>Usuário</th>
                  <th style={{ paddingRight: "5px" }}>Apontamento</th>
                  <th>Quantidade</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {notes.map((note, idx) => (
                  <tr key={idx}>
                    <td style={{ paddingRight: "5px" }}>
                      {getLocalDate(note?.DHAPO?.toString())}{" "}
                      {getLocalTime(note?.DHAPO)}
                    </td>
                    <td style={{ paddingRight: "5px" }}>{note.CODUSU}</td>
                    <td style={{ paddingRight: "5px" }}>{note.NUAPO}</td>
                    <td>{note.QTDEPROD}</td>
                    <td>
                      {handlePrint && (
                        <MdPrint
                          size={24}
                          style={{ cursor: "pointer" }}
                          onClick={() => handlePrint(note?.NUAPO.toString())}
                          color={"#dd5400"}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card.Body>
        </Card.Body>
      </StyledCard>
    </div>
  );
}
